<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :check.sync="check"
          :select.sync="select"
          :isOld="isOld"
          :disabled="disabled"
          :contentHeight.sync="contentHeight"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fireFightingMasterDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingCheckId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'info',
      check: {
        sopFireFightingCheckId: '',  // 소방설비 점검 일련번호
        plantCd: '',  // 사업장
        sopFireFightingCheckTypeCd: null,  // 점검유형
        checkName: '',  // 점검명
        sopFireFightingCheckStepCd: '',  // 점검진행상태
        checkStartDate: '',  // 점검시작일
        checkEndDate: '',  // 점검종료일
        period: [],
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        targets: [],
        deleteTargets: [],
        imprs: [],
      },
      select: {
        sopFireFightingId: '',
        sopFireFightingCheckTargetId: '',
        sopFireFightingKindCd: '',
        fireFightingName: '',
        checkResult: '',
        editFlag: '',
        results: [],
      },
      editable: '',
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopFireFightingCheckId)
    },
    disabled() {
      return this.check.sopFireFightingCheckStepCd === 'FFC0000005'
    },
    tabHeight() {
      return String(this.contentHeight - 70);
    },
    tabItems() {
      let _items = [
        { name: 'info', icon: 'info', label: '점검상세', component: () => import(`${'./fireFightingCheckInfo.vue'}`) },
      ]
      if (this.isOld) {
        _items.push({
          name: 'impr', icon: 'construction', label: '개선관리', component: () => import(`${'./fireFightingImpr.vue'}`)
        })
      }
      return _items;
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.fft.fireFightingCheck.get.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopFireFightingCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.check, _result.data)
          this.$set(this.check, 'period', [this.check.checkStartDate, this.check.checkEndDate])
          if (this.check.targets && this.check.targets.length > 0 && !this.select.sopFireFightingId) {
            this.select = {
              sopFireFightingId: this.check.targets[0].sopFireFightingId,
              sopFireFightingCheckTargetId: this.check.targets[0].sopFireFightingCheckTargetId,
              sopFireFightingKindCd: this.check.targets[0].sopFireFightingKindCd,
              fireFightingName: this.check.targets[0].fireFightingName,
              checkResult: this.check.targets[0].checkResult,
              editFlag: '', 
              results: this.check.targets[0].results,
            }
          }
        },);
      } else {
        // 
        this.$set(this.check, 'sopFireFightingCheckStepCd', 'FFC0000001')
      }
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>